
import * as cloudflareRuntime$qMD5eHmand from '/app/node_modules/.pnpm/@nuxt+image@1.3.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.21.2_webpack-sources@3.2.3/node_modules/@nuxt/image/dist/runtime/providers/cloudflare'
import * as storyblokRuntime$usI3YP0itU from '/app/node_modules/.pnpm/@nuxt+image@1.3.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.21.2_webpack-sources@3.2.3/node_modules/@nuxt/image/dist/runtime/providers/storyblok'
import * as ipxRuntime$KRCpPAwiqN from '/app/node_modules/.pnpm/@nuxt+image@1.3.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.21.2_webpack-sources@3.2.3/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 350,
    "sm": 600,
    "md": 800,
    "lg": 1150,
    "xl": 1400,
    "xxl": 1536,
    "2xl": 1700,
    "ml": 1080
  },
  "presets": {},
  "provider": "ipx",
  "domains": [
    "jq-creator-admin-backend-preview-images.s3.eu-central-1.amazonaws.com",
    "jq-creator-admin-backend-shops.s3.eu-central-1.amazonaws.com",
    "jq-prod-de-collage-data.s3-eu-central-1.amazonaws.com"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['cloudflare']: { provider: cloudflareRuntime$qMD5eHmand, defaults: {"baseURL":"https://www.juniqe.com"} },
  ['storyblok']: { provider: storyblokRuntime$usI3YP0itU, defaults: {"baseUrl":"https://a.storyblok.com"} },
  ['ipx']: { provider: ipxRuntime$KRCpPAwiqN, defaults: {} }
}
        