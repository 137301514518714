import revive_payload_client_5InVqo9OPl from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.17.6_encoding@0.1.13_eslint@8.57.0_ioredis@5_rks6ebhaztyorl3dx6hkygoqee/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_UQ5I5PEjiR from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.17.6_encoding@0.1.13_eslint@8.57.0_ioredis@5_rks6ebhaztyorl3dx6hkygoqee/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_hbcpLJ2Zs2 from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.17.6_encoding@0.1.13_eslint@8.57.0_ioredis@5_rks6ebhaztyorl3dx6hkygoqee/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_uZltXJC1it from "/app/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.21.2_vite@5.4.3_@types+node@20.17.6_sass@1.79_2wdyodk2fzc6r3uoso4hjtgmmq/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_dG4NXuII3Q from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.17.6_encoding@0.1.13_eslint@8.57.0_ioredis@5_rks6ebhaztyorl3dx6hkygoqee/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_1BkfbxtPGA from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.17.6_encoding@0.1.13_eslint@8.57.0_ioredis@5_rks6ebhaztyorl3dx6hkygoqee/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_acR2WiwhI5 from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.17.6_encoding@0.1.13_eslint@8.57.0_ioredis@5_rks6ebhaztyorl3dx6hkygoqee/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_TEiLhf2da9 from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.17.6_encoding@0.1.13_eslint@8.57.0_ioredis@5_rks6ebhaztyorl3dx6hkygoqee/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/packages/shop/.nuxt/components.plugin.mjs";
import prefetch_client_4yXSSWQifR from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.17.6_encoding@0.1.13_eslint@8.57.0_ioredis@5_rks6ebhaztyorl3dx6hkygoqee/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_GkCRBJ707P from "/app/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node_2pybjjopemq7xuiiqj5ar7k2fu/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_IgBl5CUF8x from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.19.2_vue@3.4.38_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_iWfqbGlkfw from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.19.2_vue@3.4.38_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_OHU0vggoM9 from "/app/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_FhkGB2PO5a from "/app/node_modules/.pnpm/@nuxt+image@1.3.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.21.2_webpack-sources@3.2.3/node_modules/@nuxt/image/dist/runtime/plugin.mjs";
import plugin_bFLfxHwfrG from "/app/node_modules/.pnpm/nuxt-graphql-client@0.2.36_@parcel+watcher@2.4.1_@types+node@20.17.6_encoding@0.1.13_enquirer_i2wpit23u77pajrjwvh7ugc7v4/node_modules/nuxt-graphql-client/dist/runtime/plugin.mjs";
import plugin_wyygwNnz95 from "/app/node_modules/.pnpm/@storyblok+nuxt@6.0.13/node_modules/@storyblok/nuxt/dist/runtime/plugin.js";
import datadog_client_cfAAU11B0P from "/app/packages/shop/plugins/datadog.client.ts";
import gql_3PotKuelAp from "/app/packages/shop/plugins/gql.ts";
import referer_9No3FWTUTl from "/app/packages/shop/plugins/referer.ts";
export default [
  revive_payload_client_5InVqo9OPl,
  unhead_UQ5I5PEjiR,
  router_hbcpLJ2Zs2,
  _0_siteConfig_uZltXJC1it,
  payload_client_dG4NXuII3Q,
  navigation_repaint_client_1BkfbxtPGA,
  check_outdated_build_client_acR2WiwhI5,
  chunk_reload_client_TEiLhf2da9,
  components_plugin_KR1HBZs4kY,
  prefetch_client_4yXSSWQifR,
  plugin_GkCRBJ707P,
  switch_locale_path_ssr_IgBl5CUF8x,
  i18n_iWfqbGlkfw,
  plugin_OHU0vggoM9,
  plugin_FhkGB2PO5a,
  plugin_bFLfxHwfrG,
  plugin_wyygwNnz95,
  datadog_client_cfAAU11B0P,
  gql_3PotKuelAp,
  referer_9No3FWTUTl
]